
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import SolanaServices from "@/core/services/SolanaServices";
import axios from "axios";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const typePassword = ref<boolean>(true);
    const agencies = ref<any>([]);
    const userRole = ref<any>();
    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email("Ingrese un correo electrónico válido")
        .required("El correo electrónico es obligatorio")
        .label("Email"),
      password: Yup.string()
        .required("La contraseña es obligatoria")
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store
        .dispatch(Actions.LOGIN, values)
        .then(async() => {
          const {data} = await SolanaServices.getTipoRol();
          if(data.data[0].type == 'Agencia' || data.data[0].type == 'Ciudad'){
            router.push({ name: "Calendario" });
          }else if(data.data[0].type == 'Múltiples agencias'){
            const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/get-current-agency");
            let currencyAgencyId = 0;
            if(data.data.id != undefined && data.data.id != null){
              currencyAgencyId = data.data.id;
            }else{
              let agenciesObj: any = await localStorage.getItem("userAgencies");
              agencies.value = JSON.parse(agenciesObj);
              console.log(agencies.value[0].id);
              const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/set-current-agency/" + agencies.value[0].id);
              currencyAgencyId = agencies.value[0].id;
            }
            router.push("/dashboard/" + currencyAgencyId);
          }else{
            router.push({ name: "dashboard" });
          }
        })
        .catch((error) => {
          const errors = store.getters.getErrors;
          Swal.fire({
            text: errors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Intentar otra vez",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    const changeType = () => {
      typePassword.value = !typePassword.value;
    };

/*     onMounted(async () => {
      let userRolObj: any = await localStorage.getItem("userRole");
      userRole.value = JSON.parse(userRolObj);
      tipoRol.value = userRole.value.tipo.name;
    }); */

    return {
      onSubmitLogin,
      login,
      submitButton,
      typePassword,
      changeType,
      agencies,
      userRole
    };
  },
});
